import React from "react";
import ReactPlayer from "react-player";

import logo from "./logo.svg";
import "./App.css";

const BackgroundVideo = () => {
  return (
    <div>
      <ReactPlayer
        playing={true}
        muted={true}
        playsinline={true}
        loop={true}
        url="https://firebasestorage.googleapis.com/v0/b/asoftwarrior.appspot.com/o/asoftwarrior.mp4?alt=media&token=661a731a-861f-4087-9450-0a9a1da4aaaa"
      />
    </div>
  );
};

function App() {
  return (
    <div className="App">
      <h1>A SOFT WARRIOR</h1>
      <header className="viewport-header">
        <div className="wrapper">
        <h2>
          Everything is a process.
          <br />
          And so is this website.
          <br /> 
          Launching soon.
        </h2>
        </div>

      </header>

      <BackgroundVideo />
    </div>
  );
}

export default App;
